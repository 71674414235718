const boards = [
  {
    id: 1,
    series: '1000 SERIES',
    product: 'Blade Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/14wTrew-PPVTmY10fvkY0Lj3H4vZx-yR5/view?usp=sharing',
  },
  {
    id: 2,
    series: '1500 SERIES',
    product: 'Blade Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/16vrcOWjuPMswyD7h7dzKBxvEYPPqErI1/view?usp=sharing',
  },
  {
    id: 3,
    series: '2000 SERIES',
    product: 'Box Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/1GaaMqDPbrGgIZZ0lUWjhOu39cr_eki40/view?usp=sharing',
  },
  {
    id: 4,
    series: '2500 SERIES',
    product: 'Box Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/1mwLBwh1CcT9FELyOA2vwqtWcUhwyOkWj/view?usp=sharing',
  },
  {
    id: 5,
    series: '3000 SERIES',
    product: 'Premium Box Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/1xCPNAw5Muo1cSoSXs4XEmwRzoiBwU_IR/view?usp=sharing',
  },
  {
    id: 6,
    series: '3500 SERIES',
    product: 'Premium Box Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/1T6P7MZQ4vKVw5BbqBx2DuJJbtdmJP-r6/view?usp=sharing',
  },
];

const slider = [
  {
    id: 1,
    series: 'HSS SERIES',
    product: 'Horizontal Slider Markerboard',
    link: 'https://drive.google.com/file/d/1rfVu1EfvS5F_WlzcHtTSED_J2rKa1kUS/view?usp=sharing',
  },
];

const glass = [
  {
    id:1,
    series: 'GLASS MB',
    product: 'Glass Markerboard',
    link: 'https://drive.google.com/file/d/1I1M2C0ZDSiS7gUzvKEgi4Lik8P4W3tRf/view?usp=sharing',
  },
];

const verticalSlider = [{
  id:1,
  series:'VSMB Series',
  product:"Vertical Sliding Markerboards",
  link:"https://drive.google.com/file/d/1EQn7LjILfDr-gjgFBe2N75kPBLVdK3Rb/view?usp=sharing"
}]

const enclosedBB = [{
  id:1,
  series:'HBC Series',
  product:"Hinged Bulletin Case",
  link:"https://drive.google.com/file/d/1P1r4YgWr8JkxH_bF9mj4TZ3S3XMH3SoJ/view"
}]


const inWallTables = [
  {
    id: 1,
    product: 'DRM-3TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1UKwvObHUOPROe3-pdUKY7Vrv2VQ4fJaI/view',
  },
  {
    id: 2,
    product: 'DRM-6TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1jkybMdd24fFx2pqWmF6wQj_PcwZRDyUv/view',
  },
  {
    id: 3,
    product: 'DRM-9TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1MvX7hUHuuru8cHl67KsOYRHrfvXg9sps/view',
  },
  {
    id: 4,
    product: 'DRM-12TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1nZ01VfxtJNt6LYOqdRcurGRUJkLvDNAq/view',
  },
  {
    id: 5,
    product: 'DSM-3TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1gJPmCXWNshHLUxBDhDRRrJv5Nc_2kwy4/view',
  },
  {
    id: 6,
    product: 'DSM-6TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1MSOWSRpr6ClSraBCC_3yzxxuG6Y4fGrS/view',
  },
  {
    id: 7,
    product: 'DSM-9TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/16IY7GiSs_ogKSA2HlgKzh4WMeiLvPzWQ/view',
  },
  {
    id: 8,
    product: 'DSM-12TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1jZjFOn26Ei8uxOYqmKi3CjpO5I40RPmC/view',
  },
];

const teachingWall = [
  {
    id: 1,
    series: 'TWS SERIES',
    product: 'Teaching Wall Slider',
    link: 'https://drive.google.com/file/d/1k3zD1hsKCoXMcbqZWUo2AN3FvWQzsMJw/view?usp=sharing',
  },
];

const hindgedDC = [
  {
    id: 1,
    series: 'HDC Series',
    product: 'Hinged Display case',
    link: 'https://drive.google.com/file/d/1vRym-jVd4Hk9oWe3-rkjBYAsmzViIYqn/view?usp=sharing',
  },
];

const slidingDC = [
  {
    id: 1,
    series: 'SDC Series',
    product: 'Sliding Display Case',
    link: 'https://drive.google.com/file/d/1WEKpBHOntsjQcUjyWa_qiVlYvZ0Oq-V6/view?usp=sharing',
  },
];

const forboWall = [
  {
    id: 1,
    series: 'FDW Series',
    product: 'Forbo Directly to Wall',
    link: 'https://drive.google.com/file/d/1YT-GVRiDjYkdhTQVr-BUsaHpEV8v2B9w/view?usp=sharing',
  },
];

const tackWall = [
  {
    id: 1,
    series: 'TWP Series',
    product: 'Tack Wall Panels',
    link: 'https://drive.google.com/file/d/1DKkr-fxemiqg5QOOMQKoc_aosxAOgQSt/view?usp=sharing',
  },
];

const markerWall = [
  {
    id: 1,
    series: 'MW Series',
    product: 'Marker Wall',
    link: 'https://drive.google.com/file/d/1UKR_fZ1ukLTqXUQ_AUwZaXe6DHCytGCe/view?usp=sharing',
  },
];

export {
  boards,
  slider,
  verticalSlider,
  glass,
  enclosedBB,
  inWallTables,
  teachingWall,
  hindgedDC,
  slidingDC,
  forboWall,
  tackWall,
  markerWall,
};
