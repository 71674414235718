import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import escribanoWalk from '../../assets/images/homepage/escribano_walk1.jpg';
import display from '../../assets/images/homepage/escribano.jpg';
import visual from '../../assets/images/homepage/visualdisplay.jpg';
import table from '../../assets/images/homepage/tables.jpg';
import tackwall from '../../assets/images/homepage/tackwall.jpg';

import styles from './HeroHover.module.css';

const Slideshow = () => {
  const [bgImage, setbgImage] = useState(visual);

  const changeToDisplay = () => {
    setbgImage(display);
  };

  const changeToVisualDisplay = () => {
    setbgImage(visual);
  };

  const changeToTables = () => {
    setbgImage(table);
  };

  const changeToTackwall = () => {
    setbgImage(tackwall);
  };

  const changeBackToDefault = () => {
    setbgImage(visual);
  };

  return (
    <section className={styles.hero_section}>
      <div
        className={styles.background}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      ></div>
      <div className={styles.product_titles_box}>
        <div className={styles.products_title_line1}>
          <Link to="/visual">
            <div
              className={`${styles.btn} ${styles.btn_visual}`}
              onMouseEnter={changeToVisualDisplay}
              onMouseLeave={changeBackToDefault}
            >
              | Visual Displays |
            </div>
          </Link>
          <Link to="/in-wall-tables">
            <div
              className={`${styles.btn} ${styles.btn_tables}`}
              onMouseEnter={changeToTables}
              onMouseLeave={changeBackToDefault}
            >
              &nbsp;  In-Wall Table |
            </div>
          </Link>
        </div>
        <div className={styles.products_title_line2}>
          <Link to="/display-cases">
            <div
              className={`${styles.btn} ${styles.btn_tables}`}
              onMouseEnter={changeToDisplay}
              onMouseLeave={changeBackToDefault}
            >
              | Display Cases &nbsp;
            </div>
          </Link>
          <Link to="/wall-systems">
            <div
              className={`${styles.btn} ${styles.btn_display}`}
              onMouseEnter={changeToTackwall}
              onMouseLeave={changeBackToDefault}
            >
            | Wall Systems |
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Slideshow;
