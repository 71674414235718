import React from 'react';

import ProductSection from '../../../components/SpecificProduct/ProductSection';
import HeroProduct from '../../../components/SpecificProduct/HeroProduct';
import HeroBody from '../../../components/SpecificProduct/HeroBody';
import ProductInfo from '../../../components/SpecificProduct/ProductInfo';
import AvailableSizes from '../../../components/SpecificProduct/AvailableSizes';

import GlassMB from '../../../assets/images/VisualBoards/Glass/Glass.png';

import { glass } from '../productData';

const GlassMarkerboard = () => {
  const mainImages = [
    {
      id: 1,
      image: GlassMB,
      alt: 'tackboard_natural_cork',
    },
  ];

  return (
    <ProductSection>
      <HeroProduct mainImages={mainImages} indicators={false} controls={false}>
        <HeroBody
          title="Glass Markerboard"
          indicators={true}
          controls={true}
          items={glass}
        >
          <li>
          Glass Boards combine style, functionality, and ease of maintenance. 
          Crafted from 1/4" low-iron, ultra-clear glass come with three distinct mounting options.
          </li>
          {/* <li>
            Or customize it with Vinyl or Fabric face wrapped onto a 1/2"
            Hardboard backing.
          </li> */}
        </HeroBody>
      </HeroProduct>
      <ProductInfo>
        <AvailableSizes>
          <li>4' x 4'</li>
          <li>4' x 5'</li>
          <li>4' x 6'</li>
          <li>4' x 7'</li>
          <li>4' x 8'</li>
          <li>4' x 9'</li>
          <li>4' x 10'</li>
          <li>4' x 11'</li>
          <li>4' x 12'</li>
          <li>4' x 13'</li>
          <li>4' x 14'</li>
          <li>4' x 15'</li>
          <li>4' x 16'</li>
          <li>Custom Sizes Available</li>
        </AvailableSizes>

      </ProductInfo>
    </ProductSection>
  );
};

export default GlassMarkerboard;
